// https://stackoverflow.com/a/57401891/10176917
export function shadeColor(color: string, amount: number) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

export const backgroundColorTransition = {
    transition: "background-color 2000ms",
};


export const primaries = [
    "#E91E63",
    "#F44336",
    // "#FF9800",
    "#FFEB3B",
    "#4CAF50",
    "#009688",
    "#2196F3",
    "#9C27B0",
    // "#795548",
];