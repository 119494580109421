import {svgStyle} from "../BackgroundImage";

export default function WalkingTogether({color}: { color: string }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="537.64"
             height="508.91"
             viewBox="0 0 537.64 508.91"
             style={svgStyle}
        >
            <path
                d="m0,480.14c0,.66.53,1.19,1.19,1.19h535.26c.66,0,1.19-.53,1.19-1.19s-.53-1.19-1.19-1.19H1.19c-.66,0-1.19.53-1.19,1.19Z"
                fill="#2f2e43" strokeWidth="0"/>
            <path
                d="m160.71,21.3l-4.21,10.53s-3.88,14.74.67,20.62c4.54,5.87,5.55,50.88.67,54.2-4.88,3.33,61.85-30.82,61.85-30.82,0,0-26.05-60.41-26.49-60.63s-17.51-5.54-17.51-5.54l-14.96,11.64s-.02,0-.02,0Z"
                fill="#2f2e43" strokeWidth="0"/>
            <polygon points="204.16 73.35 181.8 80.7 181.8 48.52 202.08 48.52 204.16 73.35" fill="#f3a3a6"
                     strokeWidth="0"/>
            <path id="uuid-bb1e70bb-146f-43f8-9ef8-107ef6e1721c-44-89-87-44-44-105-101"
                  d="m135.81,256.05c-1.21,7.37-6.13,12.66-10.98,11.81-4.85-.85-7.81-7.52-6.59-14.9.44-2.95,1.61-5.75,3.41-8.14l5.54-31.17,15.08,3.16-7.07,30.39c.93,2.88,1.14,5.91.61,8.85h0Z"
                  fill="#f3a3a6" strokeWidth="0"/>
            <rect x="174.81" y="433.64" width="20.94" height="29.71" fill="#f3a3a6" strokeWidth="0"/>
            <path
                d="m154.42,481.33c-2.2,0-4.16-.05-5.64-.19-5.56-.51-10.87-4.62-13.54-7.02-1.2-1.08-1.58-2.8-.96-4.28h0c.45-1.06,1.34-1.86,2.45-2.17l14.7-4.2,23.8-16.06.27.48c.1.18,2.44,4.39,3.22,7.23.3,1.08.22,1.98-.23,2.68-.31.48-.75.76-1.1.92.43.45,1.78,1.37,5.94,2.03,6.07.96,7.35-5.33,7.4-5.59l.04-.21.18-.12c2.89-1.86,4.67-2.71,5.28-2.53.38.11,1.02.31,2.75,17.44.17.54,1.38,4.48.56,8.25-.89,4.1-18.81,2.69-22.4,2.37-.1.01-13.52.97-22.71.97h0Z"
                fill="#2f2e43" strokeWidth="0"/>
            <rect x="243.89" y="413.6" width="20.94" height="29.71" transform="translate(-188.2 199.51) rotate(-31.95)"
                  fill="#f3a3a6" strokeWidth="0"/>
            <path
                d="m231.78,477.26c-2.46,0-4.72-.3-6.33-.58-1.58-.28-2.82-1.54-3.08-3.12h0c-.18-1.14.15-2.29.93-3.14l10.25-11.34,11.7-26.22.48.26c.18.1,4.39,2.43,6.56,4.43.83.76,1.24,1.57,1.22,2.4,0,.58-.23,1.04-.45,1.37.6.16,2.23.22,6.11-1.42,5.66-2.39,3.42-8.41,3.32-8.66l-.08-.2.09-.19c1.47-3.11,2.52-4.77,3.14-4.94.39-.11,1.03-.28,11.56,13.35.43.36,3.54,3.07,4.84,6.7,1.41,3.95-14.54,12.24-17.75,13.86-.1.08-16.79,12.21-23.65,15.66-2.72,1.37-5.94,1.79-8.87,1.79h0Z"
                fill="#2f2e43" strokeWidth="0"/>
            <path
                d="m211.17,185.19h-58.63l-5.32,54.54,23.28,201.52h29.93l-11.97-116.39,48.55,105.08,26.6-18.62-37.91-98.1s13.54-85.46,2.9-106.75c-10.64-21.28-17.43-21.28-17.43-21.28h0Z"
                fill="#2f2e43" strokeWidth="0"/>
            <polygon points="240.33 188.52 138.58 188.52 175.16 68.14 215.73 68.14 240.33 188.52" fill={color}
                     strokeWidth="0"/>
            <path d="m181.56,68.15s-25.27-.67-27.93,5.99c-2.66,6.65-33.92,164.94-33.92,164.94h20.62l41.24-170.93h-.01Z"
                  fill={color} strokeWidth="0"/>
            <circle cx="184.57" cy="35.14" r="22.28" fill="#f3a3a6" strokeWidth="0"/>
            <path
                d="m188.89.53s26.82-6.87,35.03,28.82c0,0,.22,9.31,4.88,13.08s3.1,23.28,3.1,23.28c0,0,8.87,14.63,1.33,21.28,0,0-3.33,13.52,1.55,21.28s23.28,50.44-7.32,53.04c0,0-15.52-9.37-8.2-37.3s-.89-43.36-.89-43.36c0,0-23.5-8.52-22.17-26.7,1.33-18.18-15.08-36.14-16.18-35.91s-12.64-6.43-17.74,13.97l-5.46-.93S154.53-3.24,188.89.53Z"
                fill="#2f2e43" strokeWidth="0"/>
            <path id="uuid-eeb5b608-6ee1-4f81-854a-587534d39606-45-90-88-45-45-106-102"
                  d="m257.7,252.48c1.49,7.32-1.24,14.01-6.08,14.94-4.84.93-9.97-4.26-11.45-11.58-.63-2.92-.53-5.94.29-8.82l-5.89-31.11,15.22-2.41,4.19,30.92c1.89,2.36,3.16,5.12,3.72,8.06h0Z"
                  fill="#f3a3a6" strokeWidth="0"/>
            <path d="m198,68.29s22.26-2.82,24.92,3.83,33.92,164.94,33.92,164.94h-20.62l-38.22-168.77h0Z" fill={color}
                  strokeWidth="0"/>
            <path id="uuid-88df1b5d-ca7e-43f8-926d-2353a0ed292e-46-91-89-46-46-107-103"
                  d="m258.58,233.97c-5.4,5.16-6.99,12.21-3.54,15.73,3.44,3.53,10.61,2.2,16.02-2.97,2.19-2.03,3.83-4.57,4.8-7.41l22.6-22.18-11.12-10.66-21.09,22.99c-2.9.86-5.52,2.4-7.65,4.49h-.02Z"
                  fill="#f3a3a6" strokeWidth="0"/>
            <path
                d="m267.39,223.59l41.71-53.46,21.95-57.45c2.4-6.27,7.45-11,13.87-12.97,6.42-1.97,13.26-.9,18.76,2.95,8.99,6.28,11.86,18.31,6.68,27.98l-33.83,63.13-.05.1-57.89,43.82-11.2-14.08v-.02Z"
                fill={color} strokeWidth="0"/>
            <polygon points="365.14 106.48 339.12 115.03 339.12 77.59 362.72 77.59 365.14 106.48" fill="#f3a3a6"
                     strokeWidth="0"/>
            <circle cx="342.34" cy="62.03" r="25.92" fill="#f3a3a6" strokeWidth="0"/>
            <path
                d="m348.94,64.06l-3.57,4.34-6.46-11.31s-8.25.43-8.25-5.7-7.57-6.29-7.57-6.29c0,0-10.72-18.71,11.48-13.78,0,0,15.39-10.55,23.05-1.53,0,0,23.47,11.82,16.75,32.4l-11.16,21.21-2.53-4.8s1.53-20.16-11.74-14.54Z"
                fill="#2f2e43" strokeWidth="0"/>
            <rect x="351.34" y="461.22" width="20.94" height="29.71" fill="#f3a3a6" strokeWidth="0"/>
            <path
                d="m330.94,508.91c-2.2,0-4.16-.05-5.64-.19-5.56-.51-10.87-4.62-13.54-7.02-1.2-1.08-1.58-2.8-.96-4.28h0c.45-1.06,1.34-1.86,2.45-2.17l14.7-4.2,23.8-16.06.27.48c.1.18,2.44,4.39,3.22,7.23.3,1.08.22,1.98-.23,2.68-.31.48-.75.76-1.1.92.43.45,1.78,1.37,5.94,2.03,6.07.96,7.35-5.33,7.4-5.59l.04-.21.18-.12c2.89-1.86,4.67-2.71,5.28-2.53.38.11,1.02.31,2.75,17.44.17.54,1.38,4.48.56,8.25-.89,4.1-18.81,2.69-22.4,2.37-.1.01-13.52.97-22.71.97h0Z"
                fill="#2f2e43" strokeWidth="0"/>
            <rect x="407.56" y="419.96" width="20.94" height="29.71" transform="translate(-181.23 366.24) rotate(-39.6)"
                  fill="#f3a3a6" strokeWidth="0"/>
            <path
                d="m415.19,479.74c-1.7,1.4-3.24,2.61-4.46,3.45-4.61,3.15-11.32,3.37-14.9,3.22-1.61-.07-3-1.15-3.47-2.69h0c-.33-1.11-.15-2.29.5-3.24l8.65-12.61,8.1-27.54.51.2c.19.07,4.67,1.83,7.09,3.52.92.64,1.43,1.39,1.53,2.21.07.57-.09,1.07-.26,1.42.62.07,2.24-.08,5.87-2.22,5.29-3.12,2.27-8.79,2.13-9.03l-.1-.19.06-.2c1.04-3.28,1.87-5.06,2.46-5.31.37-.16.98-.42,13.23,11.69.48.3,3.92,2.57,5.69,6,1.93,3.73-12.78,14.07-15.75,16.1-.07.07-9.8,9.36-16.88,15.22h0Z"
                fill="#2f2e43" strokeWidth="0"/>
            <path d="m316.71,232.11h71.83l-15.96,237.43h-23.94l-31.92-237.43h0Z" fill="#2f2e43" strokeWidth="0"/>
            <polygon
                points="331.34 245.19 316.71 232.11 324.02 362.69 405.83 440.5 426.23 423.65 378.56 361.8 331.34 245.19"
                fill="#2f2e43" strokeWidth="0"/>
            <path
                d="m331.34,92.44l35.47-5.76,11.02,19.26c14,24.48,19.71,52.84,16.28,80.84l-5.57,45.33h-71.83l14.63-139.67s0,0,0,0Z"
                fill={color} strokeWidth="0"/>
            <path id="uuid-46bdddeb-8c3f-4fea-a5c7-33aa4ef4575e-47-92-90-47-47-108-104"
                  d="m258.87,243.34c-5.96,4.51-8.35,11.32-5.34,15.22s10.29,3.41,16.25-1.1c2.41-1.77,4.34-4.1,5.62-6.81l25-19.42-9.82-11.88-23.61,20.41c-2.98.52-5.76,1.75-8.12,3.58h.02Z"
                  fill="#f3a3a6" strokeWidth="0"/>
            <path
                d="m268.82,234.04l47.6-48.29,28.43-54.53c3.1-5.95,8.67-10.07,15.28-11.29,6.6-1.22,13.27.64,18.3,5.09,8.21,7.28,9.67,19.56,3.41,28.56l-40.89,58.8-.06.09-62.56,36.84-9.5-15.28h-.01Z"
                fill={color} strokeWidth="0"/>
        </svg>
    );
}