import {svgStyle} from "../BackgroundImage";

export default function FunMoments({color}: { color: string }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="703.71" height="700.67" viewBox="0 0 703.71 700.67"
             style={svgStyle}
        >
            <path
                d="m367.05,237.82c-7.55,3.86-16.37,3.62-24.62,4.11s-16.18,1.52-22.16,7.12c-6.18,5.79-7.44,14.49-5.89,22.48,1.73,8.98,6.67,16.49,12.52,23.34,6.29,7.37,13.47,14.19,18.06,22.83,4.06,7.64,5.38,16.36,4.23,24.91-2.37,17.71-13.93,33.33-28.59,43.03-16.88,11.18-36.95,16.01-56.54,19.99-2.49.51-4.98,1-7.47,1.49-1.58.31-.91,2.72.66,2.41,19.74-3.88,39.95-7.94,57.8-17.65,8.21-4.46,15.76-10.11,21.83-17.25,5.68-6.68,10.16-14.43,12.78-22.82s3.22-16.82,1.31-25.11c-2.1-9.13-7.51-16.74-13.56-23.7s-13.19-13.78-17.34-22.33c-3.55-7.3-5.61-16.71-2.02-24.39,3.6-7.7,11.58-10.59,19.51-11.46,8.33-.91,16.92-.32,25.06-2.62,1.96-.55,3.87-1.3,5.68-2.23,1.43-.73.17-2.89-1.26-2.16h0Z"
                fill={color}/>
            <path
                d="m0,699.48c0,.66.53,1.19,1.19,1.19h258.84c.66,0,1.19-.53,1.19-1.19s-.53-1.19-1.19-1.19H1.19c-.66,0-1.19.53-1.19,1.19Z"
                fill="#3f3d58"/>
            <path
                d="m442.49,699.48c0,.66.53,1.19,1.19,1.19h258.84c.66,0,1.19-.53,1.19-1.19s-.53-1.19-1.19-1.19h-258.84c-.66,0-1.19.53-1.19,1.19Z"
                fill="#3f3d58"/>
            <polygon points="365.84 244.42 471.58 81.81 365.84 0 260.09 81.81 365.84 244.42" fill={color}/>
            <rect x="365.33" y="0" width="1" height="244.42" fill="#2f2e43"/>
            <path
                d="m260.3,82.26l-.42-.91c.27-.12,27.21-12.57,66.6-18.75,36.36-5.7,91.1-7.07,145.32,18.75l-.43.9c-53.98-25.71-108.49-24.34-144.71-18.67-39.26,6.15-66.1,18.54-66.36,18.67Z"
                fill="#2f2e43"/>
            <polygon points="198.72 447.94 215.87 447.47 207.93 425.39 194.01 430.39 198.72 447.94" fill="#faafb2"/>
            <circle cx="202.74" cy="416.9" r="16.25" fill="#faafb2"/>
            <g>
                <rect x="187.14" y="670.49" width="12.73" height="18.06"
                      transform="translate(387.02 1359.03) rotate(-180)" fill="#faafb2"/>
                <path
                    d="m198.46,698.89c-2.18.2-13.07,1.06-13.61-1.44-.5-2.29.23-4.69.34-5.01,1.05-10.42,1.44-10.53,1.67-10.6.37-.11,1.45.4,3.21,1.54l.11.07.02.13c.03.16.81,3.99,4.5,3.4,2.53-.4,3.35-.96,3.61-1.23-.22-.1-.48-.27-.67-.56-.27-.42-.32-.97-.14-1.63.48-1.73,1.9-4.29,1.96-4.4l.16-.29,14.47,9.76,8.93,2.55c.67.19,1.22.67,1.49,1.32h0c.38.9.14,1.95-.58,2.6-1.62,1.46-4.85,3.95-8.23,4.27-.9.08-2.09.12-3.43.12-5.59,0-13.74-.58-13.8-.59Z"
                    fill="#2f2e43"/>
            </g>
            <g>
                <rect x="135" y="666.59" width="12.73" height="18.06"
                      transform="translate(147.45 1365.84) rotate(-168.69)" fill="#faafb2"/>
                <path
                    d="m142.42,695.59c-2.17-.23-13.02-1.53-13.07-4.08-.04-2.35,1.15-4.55,1.32-4.85,3.07-10.01,3.47-10.05,3.72-10.07.39-.03,1.35.68,2.85,2.14l.09.09v.13c0,.17.01,4.07,3.74,4.22,2.56.1,3.47-.28,3.78-.5-.19-.14-.42-.36-.55-.68-.19-.47-.13-1.02.18-1.63.81-1.6,2.7-3.83,2.78-3.93l.21-.25,12.27,12.41,8.26,4.26c.62.32,1.06.9,1.2,1.59h0c.19.96-.24,1.94-1.08,2.44-1.88,1.11-5.53,2.93-8.91,2.57-.9-.1-2.07-.3-3.38-.56-5.48-1.1-13.36-3.26-13.42-3.28Z"
                    fill="#2f2e43"/>
            </g>
            <polygon
                points="220.37 524.94 159.74 518.48 131.84 668.86 151.25 671.28 183.99 572.24 182.37 674.11 203.39 674.11 220.37 524.94"
                fill="#2f2e43"/>
            <path
                d="m217.13,438.47l-33.14-3.68-32.74,105.91s16.57-3.23,26.68,7.28c10.11,10.51,44.91-1.43,44.91-1.43l-5.71-108.09Z"
                fill="#dfdfe0"/>
            <path id="uuid-55fa1ace-1880-4e3c-ae22-5abe9602db63-219"
                  d="m248.32,550.05c.9,4.44-.75,8.5-3.69,9.06-2.94.56-6.05-2.58-6.95-7.03-.39-1.77-.32-3.61.18-5.35l-3.57-18.87,9.23-1.46,2.54,18.76c1.15,1.43,1.92,3.11,2.25,4.89,0,0,0,0,0,0Z"
                  fill="#faafb2"/>
            <path
                d="m204.41,437.22l12.72,1.25c6.13,1.3,10.9,6.11,12.16,12.25l18.5,89.97h-12.51s-30.88-103.48-30.88-103.48Z"
                fill="#dfdfe0"/>
            <path
                d="m193.65,427.02l1.6-.73c-.67-3.02-1.28-6.42.42-9,.94-1.42,2.54-2.54,2.74-4.23.17-1.4-.72-2.83-.33-4.19.41-1.43,2.01-2.09,3.42-2.57,2.21-.74,4.46-1.49,6.78-1.55,2.33-.05,4.79.7,6.27,2.49.46.55.81,1.19,1.15,1.81,1.17,2.12,3.19,6.22,3.19,6.22,0,0-.08-.94-.07-2.81.59-1.64,1.43-3.23,1.57-4.96.17-2.09-.7-4.14-1.88-5.87-2.68-3.93-7.19-6.66-11.95-6.87-4.76-.22-9.12.11-11.53,4.22-.45.77-.82,1.6-1.39,2.29-1.05,1.28-2.64,1.96-3.93,2.99-2.87,2.3-4.89,6.53-4.69,10.21.2,3.68,1.55,7.18,2.89,10.61,1.15,2.96,3.52,5.97,6.52,7.01-.32-1.59-.63-3.18-.95-4.77"
                fill="#2f2e43"/>
            <g>
                <path
                    d="m204.03,439.15l-.19.26-5.78,7.9-6.67,9.13-1.5,2.05-5.5,7.53s-1.76,2.09-4.66,4.71l-.06.05c-1.04.94-2.22,1.94-3.52,2.94-7.35,5.66-18.41,11.19-28.01,3.76-12.19-9.44,14.55-30.48,28.16-40.08,4.47-3.16,10.17-4.01,15.37-2.31l12.36,4.06Z"
                    fill="#dfdfe0"/>
                <path
                    d="m203.72,450.25c-1.23.82-2.62,1.37-4.08,1.64-.33.06-.66.11-1,.14l-6.92,5.11-1.83,1.35-6.48,4.79-5.39-7.64,9.95-6.57,6.08-4.02c.91-1.57,2.18-2.9,3.72-3.85,1.88-1.21,3.92-1.81,5.69-1.81h.01c.13,0,.25,0,.37.02,1.65.07,3.04.7,3.81,1.88,1.65,2.5-.12,6.51-3.93,8.96Z"
                    fill="#faafb2"/>
                <polygon points="192.91 459.7 187.83 448.74 165.32 457.14 176.15 473.72 192.91 459.7" fill="#dfdfe0"/>
            </g>
            <path
                d="m205.73,473.83l-4.31-19.51c-2.11-9.55,3.3-18.99,12.59-21.94,59.96-19.09,85.59-51.21,95.53-68.66,32.19-56.51,55.6-118.85,55.83-119.47l.94.35c-.23.62-23.66,63.04-55.9,119.62-23.33,40.95-70.22,60.88-96.1,69.12-8.79,2.8-13.91,11.73-11.91,20.77l4.31,19.51-.98.21Z"
                fill="#2f2e43"/>
            <path
                d="m203.72,450.25c-1.23.82-2.62,1.37-4.08,1.64-.98-1.33-1.55-2.9-1.58-4.58v-.12c0-3.36,2.18-6.28,5.4-7.8h.01c.18-.09-.19.07,0,0l.37.02c1.65.07,3.04.7,3.81,1.88,1.65,2.5-.12,6.51-3.93,8.96Z"
                fill="#faafb2"/>
            <path
                d="m591.63,400.56s-12.5-3.03-20.07,15.27c-6.31,15.25-10.78,23.4-5.66,28.94,1.83,1.98,4.44,3.05,7.14,3.14l28.82.94s10.58-27.74,4.15-37.77c-6.43-10.03-14.37-10.52-14.37-10.52Z"
                fill="#2f2e43"/>
            <polygon points="597.6 446.56 583.74 451.11 583.74 431.16 596.31 431.16 597.6 446.56" fill="#faafb2"/>
            <polygon points="586.96 688.33 573.98 688.33 566.45 618.96 586.52 618.96 586.96 688.33" fill="#faafb2"/>
            <path
                d="m561.34,699.48c-1.37,0-2.58-.03-3.5-.12-3.45-.32-6.74-2.86-8.39-4.35-.74-.67-.98-1.73-.59-2.65h0c.28-.66.83-1.15,1.52-1.35l9.11-2.6,14.75-9.95.16.3c.06.11,1.51,2.72,2,4.48.18.67.14,1.23-.14,1.66-.19.3-.46.47-.68.57.27.28,1.1.85,3.68,1.26,3.77.6,4.55-3.3,4.59-3.47l.02-.13.11-.07c1.79-1.15,2.89-1.68,3.27-1.57.24.07.64.19,1.7,10.81.11.33.86,2.78.35,5.11-.56,2.54-11.66,1.67-13.88,1.47-.06,0-8.38.6-14.08.6Z"
                fill="#2f2e43"/>
            <polygon points="616.29 688.33 603.31 688.33 595.78 618.96 615.85 618.96 616.29 688.33" fill="#faafb2"/>
            <path
                d="m590.67,699.48c-1.37,0-2.58-.03-3.5-.12-3.45-.32-6.74-2.86-8.39-4.35-.74-.67-.98-1.73-.59-2.65h0c.28-.66.83-1.15,1.52-1.35l9.11-2.6,14.75-9.95.16.3c.06.11,1.51,2.72,2,4.48.18.67.14,1.23-.14,1.66-.19.3-.46.47-.68.57.27.28,1.1.85,3.68,1.26,3.77.6,4.55-3.3,4.59-3.47l.02-.13.11-.07c1.79-1.15,2.9-1.68,3.27-1.57.24.07.64.19,1.7,10.81.11.33.86,2.78.35,5.11-.55,2.54-11.66,1.67-13.88,1.47-.06,0-8.38.6-14.08.6Z"
                fill="#2f2e43"/>
            <polygon points="620.03 517.95 556.95 517.95 579.62 443.32 604.77 443.32 620.03 517.95" fill="#dfdfe0"/>
            <g>
                <path id="uuid-fd4f2c97-fb62-4a05-8832-5ff88c74de66-220"
                      d="m555.23,559.82c-.75,4.57-3.8,7.85-6.81,7.32-3.01-.53-4.84-4.66-4.09-9.23.27-1.83,1-3.56,2.11-5.05l3.44-19.32,9.35,1.96-4.38,18.84c.58,1.78.71,3.66.38,5.49,0,0,0,0,0,0Z"
                      fill="#faafb2"/>
                <path d="m583.59,443.34s-15.67-.41-17.32,3.71c-1.65,4.12-21.03,102.25-21.03,102.25h12.78l25.56-105.96Z"
                      fill="#dfdfe0"/>
            </g>
            <circle cx="586.92" cy="422.06" r="13.81" fill="#faafb2"/>
            <path d="m589.09,405.12s-17.73,26.38-14.75,42.83l-10.31-6.46s7.26-33.31,18.09-35.9l6.98-.48Z"
                  fill="#2f2e43"/>
            <path
                d="m606.92,517.95h-41.4l-22.65,92.96c-.42,3.93,2.07,7.59,5.89,8.61,12.43,3.33,42.25,8.04,87.38-5.09,4.34-1.26,6.81-5.85,5.44-10.16l-34.66-86.32Z"
                fill="#2f2e43"/>
            <g>
                <path
                    d="m590.18,443.32l12.88-.57c5.58.42,10.62,3.46,13.61,8.18,9.1,14.38,26.12,44.7,10.92,48.85-20.03,5.47-29.74-24.99-29.74-24.99l-7.67-31.48Z"
                    fill="#dfdfe0"/>
                <path id="uuid-5f0663b3-514b-4d25-bf17-95b52eadc03c-221"
                      d="m585.76,452.36c-2.65-3.8-2.74-8.28-.21-10,2.53-1.72,6.72-.03,9.36,3.78,1.08,1.5,1.77,3.25,2,5.09l11,16.26-8.07,5.11-9.98-16.57c-1.66-.88-3.06-2.14-4.09-3.67,0,0,0,0,0,0Z"
                      fill="#faafb2"/>
                <polygon points="592.27 465.51 601.36 457.14 619.33 473.87 602.62 485.29 592.27 465.51" fill="#dfdfe0"/>
            </g>
        </svg>
    );
}