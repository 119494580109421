import {createTheme} from "@mui/material";

export const themeFromColors = ({main, background}: { main: string, background: string }) => createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: main,
        },
        background: {
            default: background,
        },
    },
    typography: {
        fontFamily: [
            'Joan',
            'serif',
        ].join(','),
    },
});