import {AppBar, IconButton, Toolbar, Typography} from "@mui/material";
import Logo from "./Logo";
import {useTheme} from "@mui/material/styles";
import {backgroundColorTransition} from "../colorUtils";


export default function Masthead() {
    const theme = useTheme();

    return (
        <AppBar position="fixed" elevation={0}
                sx={{
                    backdropFilter: "blur(10px)",
                    // add transparency to hex
                    backgroundColor: theme.palette.background.default + "88",
                    ...backgroundColorTransition,
                }}>
            <Toolbar>
                <IconButton size="large" edge="start">
                    <Logo/>
                </IconButton>
                <Typography variant="h5" fontWeight={"bold"}>
                    Tacit
                </Typography>
            </Toolbar>
        </AppBar>
    )
}