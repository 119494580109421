import {svgStyle} from "../BackgroundImage";

export default function Relaxation({color}: { color: string }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="885.67004" height="595.53695" viewBox="0 0 885.67004 595.53695"
             style={svgStyle}
        >
            <circle cx="109.2805" cy="271.71071" r="65" fill="#ff6884"/>
            <path
                d="M137.44792,593.68761H12.2805L40.56791,139.68761,59.02794,32.68761l3.51837-18.05263c4.16594-21.37531,35.59571-18.52076,35.84411,3.25531,.57752,50.62808-20.63779,98.27479-19.60247,121.79732,4.66998,106.48999,14.42999,257.48999,33.23999,302,13.96997,33.07001,21.44,139.56,25.41998,152Z"
                fill="#3f3d56"/>
            <path
                d="M869.44792,593.68761h-115.71997l-12.16003-454-23.31506-106.98019c-.26832-21.82772,31.19235-25.3629,35.77505-4.01998l.00004,.00018s22.96997,47.60999,25.76001,111c4.66998,106.48999,45.42999,257.48999,64.23999,302,13.96997,33.07001,21.44,139.56,25.41998,152Z"
                fill="#3f3d56"/>
            <path
                d="M175.52794,296.18761c26.17947,2.10492,86.99994,33.99994,87,34,47.26279,47.31976,117.85486,50.12592,165,52,70.09753,2.7865,123.16309-19.03186,162-35,.00012-.00006,90.79102-20.91016,92-21,1.4469-.10751,2.81732,3.4599,3,4,2.99634,8.85846-29.948,41.67468-68,53-5.18396,1.54291-16.94427,4.61603-29,14-7.4845,5.82581-11.88135,11.59204-13,13-10.21014,12.85089-64.46973,61.80426-126,64-52.62238,1.87787-94.68774-31.01001-142-68-18.7652-14.67114-16.51492-15.75439-40-34-53.26199-41.37933-79.89301-62.06903-92-69-1.67798-.9606-7.55383-4.24442-7-6,.50555-1.60257,6.12259-1.15094,8-1Z"
                fill={color}/>
            <path
                d="M167.52696,298.18761c-.18018,0-.3623-.04883-.52637-.15039L36.796,217.21642c-.46924-.29102-.61377-.90771-.32227-1.37695,.29102-.47021,.9082-.61475,1.37695-.32227l130.20459,80.8208c.46924,.29102,.61377,.9082,.32227,1.37695-.18945,.30469-.51611,.47266-.85059,.47266Z"
                fill="#3f3d56"/>
            <path
                d="M681.52891,327.18761c-.36084,0-.70898-.19531-.88721-.53711-.25586-.48926-.06641-1.09375,.42334-1.34961l112.6416-58.82861c.49121-.25586,1.09375-.06641,1.34912,.42334,.25586,.48926,.06641,1.09375-.42334,1.34961l-112.6416,58.8291c-.14795,.07715-.30615,.11328-.46191,.11328Z"
                fill="#3f3d56"/>
            <path
                d="M0,594.34695c0,.66003,.53003,1.19,1.19006,1.19H160.48004c.65997,0,1.19-.52997,1.19-1.19,0-.65997-.53003-1.19-1.19-1.19H1.19006c-.66003,0-1.19006,.53003-1.19006,1.19Z"
                fill="#3f3d56"/>
            <path
                d="M713,594.34695c0,.66003,.53003,1.19,1.19006,1.19h170.28998c.65997,0,1.19-.52997,1.19-1.19,0-.65997-.53003-1.19-1.19-1.19h-170.28998c-.66003,0-1.19006,.53003-1.19006,1.19Z"
                fill="#3f3d56"/>
            <path
                d="M395,594.34695c0,.66003,.53003,1.19,1.19006,1.19h170.28998c.65997,0,1.19-.52997,1.19-1.19,0-.65997-.53003-1.19-1.19-1.19h-170.28998c-.66003,0-1.19006,.53003-1.19006,1.19Z"
                fill="#3f3d56"/>
            <g>
                <path
                    d="M278.62498,314.5665s-6.38424,10.03017-1.1334,15.93618c5.25084,5.90602,36.99406,29.43636,36.99406,29.43636,0,0,19.9973,12.26127,18.03896-4.62546-1.95833-16.88674-30.46592-34.95868-30.46592-34.95868l-23.4337-5.7884Z"
                    fill="#e6e6e6"/>
                <path
                    d="M330.51554,288.02161c-3.4147-.32934-6.60208,3.75299-7.11993,9.12044-.23628,2.14128,.04367,4.30795,.81642,6.31881l-.5108,6.08936-.10474,.01048-7.10982,27.96309c-1.65312,6.50176-.30372,13.46747,3.88249,18.70973,2.49028,3.1185,5.42304,5.02176,8.09187,2.20966,4.42847-4.66618,4.28847-27.46958,3.8211-40.50932l1.46719-13.55289c1.14364-1.82646,1.83312-3.90027,2.01073-6.04784,.51841-5.3655-1.82985-9.98213-5.24453-10.31151Z"
                    fill="#ffb6b6"/>
            </g>
            <g>
                <path
                    d="M340.68022,286.81139l-.0374-.42076c-.02803-.33449-.17774-.63664-.41161-.84165s-.53322-.28055-.82322-.22661l-14.06945,3.086c-.28999,.06474-.53322,.25895-.69224,.5395-.14971,.29129-.19647,.63657-.12162,.96026l.13092,.5611,.93547,4.02466,3.61096,15.53766c.13092,.5611,.58935,.92793,1.07576,.88479,.04682,0,.08422,0,.13098-.0108l11.12275-2.52483c.11225-.0216,.21514-.0648,.30872-.12954,.35543-.22655,.57062-.67971,.53317-1.16528l-1.6932-20.2745Z"
                    fill={color}/>
                <path
                    d="M340.4078,284.46916c-.20106,.00529-.47008,.01503-.79253,.03139,.06489-.19581,.07567-.41758,.01445-.63451l-.42513-1.50625c-.11034-.39092-.4244-.65661-.78083-.65875-2.12362-.0127-9.40178,.17755-13.65994,3.21358-.2664,.18996-.41221,.54199-.38132,.90147l.14203,1.6531c.01757,.2044,.08988,.38554,.19722,.5287-.36292,.17227-.72386,.35107-1.08056,.54056-.22353,.11875-.36384,.38054-.3607,.66438l.00787,.71192c.00501,.45176,.36402,.7876,.74827,.69997l16.8043-3.83181c.38242-.0872,.60379-.55162,.46276-.97076l-.29673-.88181c-.0956-.2841-.33503-.46814-.59917-.46118Z"
                    fill="#3f3d56"/>
            </g>
            <polygon
                points="296.927 306.78835 292.61099 320.81538 274.26794 316.49937 273.18894 293.84032 296.927 306.78835"
                fill="#ffb6b6"/>
            <path
                d="M271.03094,313.26237l23.73805,2.158,9.51151,9.29034,13.14754,11.21071s7.55302,10.79002,5.39501,24.81706l12.94803,10.79002-32.37007,33.1857-41.00209-34.2647s-14.02703-42.0811,8.63202-57.18713Z"
                fill="#e6e6e6"/>
            <path
                d="M327.13906,372.6075l9.42216-1.079,15.29295,1.079,50.24251-29.91962c6.31911-3.76306,14.48643-1.7967,18.4004,4.43007l40.43829,64.33364-12.94803,8.63202-39.92309-41.00209-49.78704,41.00209s-50.56019,26.4483-54.8762-15.36941l23.73805-32.10669Z"
                fill="#2f2e41"/>
            <polygon
                points="358.43014 410.37259 439.35532 402.81957 515.96449 422.24161 520.2805 406.05658 415.61726 368.29149 377.85218 378.00251 358.43014 410.37259"
                fill="#2f2e41"/>
            <path
                d="M267.67493,322.03693s-7.42528,9.2859-2.83961,15.72206c4.58566,6.43615,33.61602,33.24188,33.61602,33.24188,0,0,18.56365,14.33951,18.43161-2.65989-.13205-16.9994-26.53218-38.03059-26.53218-38.03059l-22.67584-8.27346Z"
                fill="#e6e6e6"/>
            <g>
                <circle cx="290.63466" cy="286.97905" r="20.48643" fill="#ffb6b6"/>
                <path
                    d="M309.52059,283.45794c-5.25399-.01258-5.55229,.02243-6.40201,0-6.51658-.17185-8.22063-2.73562-11.52362-2.5608-5.89529,.31205,1.88559,29.86914,5.12161,43.53366,3.17365,13.40131-7.5519,25.41-8.32261,26.24823-10.88662,11.83989-30.00918,14.00486-33.29044,8.96281-2.65591-4.08105,6.75665-10.28627,4.48141-19.20603-2.22414-8.71938-12.49408-7.79088-15.36482-16.00502-3.00641-8.60231,7.09676-12.94093,13.44422-33.93064,3.64536-12.05448,3.00047-14.30059,6.40201-21.76683,9.09223-19.95684,53.6584-11.45976,48.65526,7.04221-.9678,3.57896,1.60777,7.6939-3.201,7.68241Z"
                    fill="#2f2e41"/>
            </g>
            <path
                d="M169.02794,296.68761c26.17947,2.10492,86.53853,45.94497,86.53859,45.94503,47.26279,47.31976,117.85486,50.12592,165,52,70.09753,2.7865,123.16309-19.03186,162-35,.00012-.00006,80.466,13.08636,90-18,.09094-.29651,.79102-2.91016,2-3,1.4469-.10751,2.81732,3.4599,3,4,2.99634,8.85846-29.948,41.67468-68,53-5.18396,1.54291-16.94427,4.61603-29,14-7.4845,5.82581-11.88135,11.59204-13,13-10.21014,12.85089-64.46973,61.80426-126,64-52.62238,1.87787-94.68774-31.01001-142-68-18.7652-14.67114-16.51492-15.75439-40-34-53.26199-41.37933-79.89301-62.06903-92-69-1.67798-.9606-4.09242-18.18944-3.53859-19.94503,.50555-1.60257,3.12259,.84906,5,1Z"
                fill={color}/>
            <path
                d="M379.61403,403.63957c1.52016-3.07535-1.16468-7.50439-5.99865-9.89404-1.91791-.98107-4.04284-1.48856-6.19704-1.48003l-5.51136-2.63934,.02739-.10163-23.61749-16.57402c-5.49135-3.85366-12.48238-5.0651-18.86933-3.01266-3.79945,1.22095-6.61991,3.28698-4.93846,6.78031,2.79006,5.79657,24.15775,13.76124,36.51401,17.95363l12.14919,6.18311c1.30147,1.71757,2.99542,3.09837,4.94004,4.02683,4.83195,2.38948,9.98152,1.83316,11.50171-1.24215Z"
                fill="#ffb6b6"/>
            <g>
                <path
                    d="M512.22483,250.09698s-7.76959-15.53917-6.21567-29.52443l-25.85872,13.40338,.99604,25.44455,31.07835-9.3235Z"
                    fill="#a0616a"/>
                <circle cx="489.38354" cy="217.82639" r="23.32656" fill="#a0616a"/>
                <g>
                    <polygon
                        points="520.00535 584.1426 505.77112 584.14122 498.99981 529.23712 520.00821 529.23859 520.00535 584.1426"
                        fill="#a0616a"/>
                    <path
                        d="M471.23707,583.81971h0c-.4432,.74644-.67706,3.15561-.67706,4.02365h0c0,2.66814,2.16292,4.83116,4.83116,4.83116h44.08176c1.82019,0,3.29584-1.47556,3.29584-3.29584v-1.83526s2.18068-5.51593-2.30896-12.31461c0,0-5.58002,5.32347-13.918-3.01451l-2.4588-4.4543-17.79831,13.01679-9.86533,1.21433c-2.15833,.26565-4.0719-.04123-5.18212,1.82859h-.00017Z"
                        fill="#2f2e41"/>
                </g>
                <path
                    d="M478.03865,250.09698l34.18618-4.66175,26.4166,12.43134,1.55392,96.34288s-64.98312,7.76959-75.22429-2.33088c-10.24117-10.10046-2.47159-90.90417-2.47159-90.90417l15.53917-10.87742Z"
                    fill="#e6e6e6"/>
                <path
                    d="M495.21805,223.76889h6.2709s5.44093-6.69742,4.70894-2.61672c-.73199,4.0807,2.19597,8.47264,2.19597,8.47264l4.39194-1.46398s8.78388-36.93378-17.56775-38.9626c-26.35163-2.02882-32.20755,18.46689-32.20755,18.46689,0,0,1.46398,10.24786,4.39194,7.3199s19.03173-4.39194,19.03173-4.39194l8.78388,13.17582Z"
                    fill="#2f2e41"/>
                <g>
                    <polygon
                        points="462.21205 475.43875 449.19377 481.1953 420.79218 433.72257 440.0057 425.22591 462.21205 475.43875"
                        fill="#a0616a"/>
                    <path
                        d="M417.4811,494.87047h0c-.10339,.86192,.65726,3.15979,1.00839,3.95365h0c1.07928,2.44011,3.9323,3.54335,6.37249,2.46404l40.31435-17.8313c1.66463-.73628,2.41729-2.68263,1.68098-4.34734l-.74237-1.67841s-.23691-5.92661-7.09294-10.32816c0,0-2.94976,7.12565-13.9479,2.87302l-4.05045-3.07902-11.01183,19.10382-8.531,5.10113c-1.86642,1.116-3.74057,1.6094-3.99955,3.76851l-.00016,.00007Z"
                        fill="#2f2e41"/>
                </g>
                <path
                    d="M535.5336,344.88595l4.66175,14.09538v48.45286l-15.22717,43.89511,1.24191,102.34443h-27.32203l-3.08336-104.39389,2.43488-51.5607-18.64701-7.76959-48.97899,3.02294,22.90499,56.30734-25.20528,16.81167s-37.69779-85.91206-32.0572-94.23531c5.6406-8.32325,71.68199-23.00593,71.68199-23.00593,0,0,67.59551-2.41041,67.59551-3.96432Z"
                    fill="#2f2e41"/>
                <g>
                    <path
                        d="M549.51885,288.94492l7.51264,12.30434s10.62048,41.74319,0,41.91298c-10.62048,.16979-32.06331-21.27305-32.06331-21.27305l2.79583-11.18943,7.76959,3.10783-1.55392-24.86268h15.53917Z"
                        fill="#a0616a"/>
                    <path
                        d="M526.21009,257.6708s24.86268-4.38033,27.19356,11.89297c2.33088,16.27331,3.62785,31.68549,3.62785,31.68549l-26.93661,2.23151-3.88479-45.80998Z"
                        fill="#e6e6e6"/>
                    <ellipse cx="520.17846" cy="312.38134" rx="7.01522" ry="14.03044"
                             transform="translate(-36.03283 554.86172) rotate(-54.54076)" fill="#a0616a"/>
                </g>
                <g>
                    <path
                        d="M492.70288,283.30995l-.05679-.63895c-.04257-.50794-.26991-.96677-.62505-1.27809s-.80973-.42603-1.2501-.34413l-21.36519,4.68625c-.44037,.09831-.80973,.39323-1.0512,.81926-.22734,.44233-.29835,.96667-.18468,1.45821l.19881,.85206,1.42056,6.11165,5.48343,23.59474c.19881,.85206,.89496,1.4091,1.63359,1.3436,.0711,0,.12789,0,.1989-.0164l16.89048-3.83408c.17046-.0328,.3267-.09841,.46881-.19671,.53973-.34403,.86652-1.03218,.80964-1.76953l-2.57121-30.78787Z"
                        fill={color}/>
                    <path
                        d="M492.2892,279.75317c-.30532,.00803-.71384,.02283-1.2035,.04767,.09853-.29734,.1149-.63412,.02194-.96354l-.64558-2.28733c-.16755-.59364-.64448-.9971-1.18573-1.00035-3.22482-.01928-14.27709,.26962-20.74333,4.87999-.40455,.28846-.62596,.82304-.57906,1.36892l.21568,2.51032c.02669,.31039,.13649,.58547,.29949,.80285-.55111,.26161-1.09922,.53312-1.64089,.82087-.33944,.18032-.55251,.57788-.54773,1.0089l.01196,1.08109c.00761,.68602,.55278,1.19602,1.13628,1.06294l25.5182-5.8188c.58072-.13242,.91689-.83766,.70272-1.47415l-.4506-1.33908c-.14517-.43142-.50877-.71089-.90987-.70032Z"
                        fill="#3f3d56"/>
                </g>
                <g>
                    <path
                        d="M444.53821,289.94492l-7.51264,12.30434s-10.62048,41.74319,0,41.91298c10.62048,.16979,32.06331-21.27305,32.06331-21.27305l-2.79583-11.18943-7.76959,3.10783,1.55392-24.86268h-15.53917Z"
                        fill="#a0616a"/>
                    <path
                        d="M467.84698,258.6708s-24.86268-4.38033-27.19356,11.89297c-2.33088,16.27331-3.62785,31.68549-3.62785,31.68549l26.93661,2.23151,3.88479-45.80998Z"
                        fill="#e6e6e6"/>
                    <ellipse cx="473.8786" cy="313.38134" rx="14.03044" ry="7.01522"
                             transform="translate(-93.90892 333.03147) rotate(-35.45924)" fill="#a0616a"/>
                </g>
            </g>
        </svg>
    );
}