import {Box, Container, Typography} from "@mui/material";
import {globalPadding} from "../constants";
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {backgroundColorTransition} from "../colorUtils";

export default function Statement() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const variant = matches ? "h6" : "body1";

    return (
        <Box sx={{backgroundColor: theme.palette.background.default, ...backgroundColorTransition}}>
            <Container maxWidth={"xs"} sx={{p: globalPadding, pt: 16, pb: 16}}>
                <Typography variant={variant} fontWeight={"bold"} component={"h1"} paragraph>
                    Tacit is not for everyone.
                </Typography>
                <Typography variant={variant} paragraph>
                    It's for those who value personality and an emotional connection over looks.
                </Typography>
                <Typography variant={variant} paragraph>
                    On <b>Tacit</b>, there are no photos.
                    You convey your likeness through written text alone.
                    Because of that, you can be far more <i>honest</i> about who you are,
                    and what you are looking for.
                    And when you build a connection with someone based on that, instead of on
                    what they look like, it is authentic, not superficial.
                </Typography>
                <Typography variant={variant} paragraph>
                    Oh, and it's much better for your privacy, too.
                </Typography>
            </Container>
        </Box>
    )
}