import {svgStyle} from "../BackgroundImage";

export default function WinterSkating({color}: { color: string }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="886.82762" height="709.09258" viewBox="0 0 886.82762 709.09258"
             style={svgStyle}
        >
            <path
                d="M885.29852,677.33557c-2.15625,7.28094-54.10492,16.27972-86.42297,23.97559-22.52588,5.36401-45.61108,8.02234-68.76544,7.76428l-574.28021-6.4007c-36.94397-.41174-73.54864-7.10571-108.24732-19.79541L0,665.47784c49.31-13.59003,202.12-23.42029,255.96002-27.46027,45.84998-17.65002,95.44995-35.89502,149.87-34.85498,59.76001,1.13995,112.95001,25.52496,172.66998,27.23499,48.37,1.38,20.88281-.07886,65.10284-11.92389,44.21997-11.84003,25.52563,6.95691,71.84564,8.01184,41.01996,.935,81.70001-16.01495,120.90009-8.69995,38.5,7.17999,56,35.72003,48.94995,59.54999Z"
                fill="#e4e5e6"/>
            <path
                d="M584.0271,680.93731H160.33228c-2.36621,0-4.29102-1.9248-4.29102-4.29102s1.9248-4.29199,4.29102-4.29199h423.69482c2.36621,0,4.29102,1.92578,4.29102,4.29199s-1.9248,4.29102-4.29102,4.29102Z"
                fill="#cfd1d2"/>
            <path
                d="M372.08765,700.81329H175.62914c-2.36621,0-4.29102-1.9248-4.29102-4.29102s1.9248-4.29102,4.29102-4.29102h196.45851c2.36621,0,4.2915,1.9248,4.2915,4.29102s-1.92529,4.29102-4.2915,4.29102Z"
                fill="#cfd1d2"/>
            <path
                d="M495.12183,667.03009H71.42699c-2.36621,0-4.29102-1.9248-4.29102-4.29102s1.9248-4.29199,4.29102-4.29199H495.12183c2.36621,0,4.29102,1.92578,4.29102,4.29199s-1.9248,4.29102-4.29102,4.29102Z"
                fill="#cfd1d2"/>
            <path
                d="M562.71204,654.17212c.07751,6.00696,.15503,12.01385,.23254,18.02081,.01758,1.36432,1.13141,2.48236,2.5,2.5,11.3175,.14606,22.63501,.29211,33.95245,.43823,5.65863,.07306,11.31738,.16736,16.97626,.21912,2.18176,.01996,4.41766-.10065,6.39819-1.10718,2.11493-1.07489,3.42407-3.1601,3.72394-5.47473,.33386-2.5769-.46417-5.08209-1.50482-7.40802-.98218-2.19531-2.45959-4.61945-1.60406-7.07123,.44482-1.27478-.50647-2.73468-1.74609-3.07532-1.37744-.37848-2.62885,.46667-3.07532,1.74609-1.66431,4.76971,1.68274,8.55194,2.78729,12.93622,.31342,1.24396,.53491,2.9259-.64905,3.7774-1.40295,1.00903-3.71411,.68463-5.32874,.66382-10.65173-.13745-21.30353-.27496-31.95526-.41241-5.99164-.07733-11.98322-.15466-17.97485-.23199l2.5,2.5c-.07751-6.00696-.15503-12.01385-.23254-18.02081-.0415-3.21497-5.04163-3.22388-5,0h.00006Z"
                fill="#2f2e43"/>
            <path
                d="M447.44855,621.02374c-2.32266,5.54028-4.64532,11.08057-6.96796,16.62079-.38504,.91846,.23987,2.41431,1.1489,2.8233,10.47366,4.71228,20.9473,9.4245,31.42093,14.13672,5.20425,2.34149,10.38416,4.74963,15.61893,7.02191,2.47061,1.07245,5.2222,1.98761,7.88379,1.00464,2.14114-.79077,3.57483-2.49921,4.40002-4.57977,.8266-2.08411,.91809-4.367,.86438-6.58154-.04898-2.01892-.32407-4.68835,1.17255-6.29425,2.19199-2.35193-1.33603-5.89557-3.53552-3.53552-3.20883,3.44299-2.39017,7.77625-2.64319,12.05469-.08038,1.35907-.36658,3.09406-1.39859,3.93842-1.25604,1.02765-3.457,.01654-4.81134-.58478-9.88794-4.38989-19.73337-8.87836-29.59943-13.3172l-16.84891-7.58057,1.1489,2.8233c2.32266-5.54028,4.64532-11.08057,6.96796-16.62085,.52228-1.24579-.56281-2.75018-1.74609-3.07532-1.44193-.39618-2.55103,.49554-3.07529,1.74609h0l-.00003-.00006Z"
                fill="#2f2e43"/>
            <g>
                <polygon
                    points="552.94055 601.46167 554.28766 630.71338 552.9386 638.80768 557.66034 648.25104 594.75928 652.29822 596.78284 634.7605 577.22162 624.64264 576.65295 608.11419 552.94055 601.46167"
                    fill="#ffaeb0"/>
                <path
                    d="M583.19122,621.30676l1.99524-44.62244-43.23035-2.99286s10.98248,80.63031,10.98248,84.00293,64.08002,3.37262,73.72253,2.02356,6.54608-10.11792,6.54608-10.11792l-39.98773-18.92865-10.02832-9.36469,.00006,.00006Z"
                    fill="#2f2e43"/>
            </g>
            <g>
                <polygon
                    points="458.59421 570.71905 450.50909 598.8634 446.64059 606.10028 448.09186 616.5581 481.94452 632.26507 489.47421 616.2973 474.17969 600.45141 483.7738 575.57318 458.59421 570.71905"
                    fill="#ffaeb0"/>
                <path
                    d="M482.74521,606.04889l6.729-39.99158-41.61365-10.38599s-6.18488,65.1272-7.26422,68.32245c-1.07932,3.19525,59.63062,23.70258,69.19778,25.51038,9.5672,1.8078,9.43982-7.49084,9.43982-7.49084l-29.7597-25.71594-6.729-10.24841-.00003-.00006Z"
                    fill="#2f2e43"/>
            </g>
            <polygon
                points="584.65088 275.14331 492.48071 284.25317 446.99542 593.09979 476.44553 597.63446 541.52814 381.47339 551.26727 621.30676 578.15289 621.30676 605.32617 327.71765 584.65088 275.14331"
                fill="#2f2e43"/>
            <polygon
                points="550.72345 113.74578 552.1936 137.62054 507.61234 139.88206 521.41772 108.42675 550.72345 113.74578"
                fill="#ffaeb0"/>
            <path
                d="M554.48181,113.01132s-27.54327,5.87489-47.93912-6.53997c0,0-2.60712,20.285,6.70401,29.15276,9.31116,8.86777,17.2655,57.19705,17.2655,57.19705l29.73358-1.77354,2.66034-62.07433s12.85828-15.51858-8.42438-15.96197c0,0,.00006,0,.00006,0Z"
                fill={color}/>
            <path
                d="M480.43604,255.78227s-24.60803,105.08296-21.94772,135.67674c.63364,7.28699,.62589,14.30984,.24625,20.78101-1.11093,18.93796,14.75378,34.49155,33.66901,33.04449,49.23816-3.76682,126.58142-11.26648,127.03461-21.23648,.6651-14.63181-24.27551-154.96411-42.23273-167.60068l15.62946-120.37985-26.26776-17.56319s-30.24695,12.24252-30.58813,56.13795c0,0-15.96619-52.41851-29.43631-56.13795s-48.71948,34.41193-48.71948,34.41193l22.61279,102.86603h.00003Z"
                fill="#3f3d58"/>
            <path id="uuid-cce7c04d-215f-45bf-b339-27755470716f-298"
                  d="M435.91891,368.62442c-1.41492,10.72971-8.29089,18.60953-15.35703,17.6001-7.06613-1.0094-11.64542-10.52478-10.22852-21.25809,.50513-4.2962,2.06735-8.39227,4.55591-11.9455l6.58923-45.37738,21.97568,3.8735-8.82895,44.32281c1.4769,4.12051,1.9205,8.50436,1.29367,12.78455,0,0,0,.00003,0,0Z"
                  fill="#ffaeb0"/>
            <path
                d="M439.19308,353.78866l-24.90561-5.63507,.02069-.41885,4.86014-99.90063,.02762-.07335,33.87482-90.14989c2.36456-6.2955,8.47156-10.44363,15.19556-10.32253h0c4.92468,.09047,9.45383,2.39381,12.42145,6.32381,2.96951,3.93044,3.95007,8.91621,2.68939,13.67827l-30.87451,89.24138-13.30951,97.25687h-.00003Z"
                fill="#3f3d58"/>
            <path id="uuid-ad312868-1177-40a8-a722-e44767ca9c93-299"
                  d="M593.96423,352.89889c.35663,10.81671,6.42725,19.33246,13.55835,19.02036,7.13104-.31207,12.62079-9.33289,12.26257-20.15338-.08167-4.32504-1.23492-8.55447-3.36334-12.33447l-2.1106-45.80469-22.24951,1.70126,4.44287,44.9747c-1.8736,3.95596-2.74469,8.27521-2.54034,12.59625,0,0,0,.00003,0,0v-.00003Z"
                  fill="#ffaeb0"/>
            <path
                d="M592.15997,337.81289l-.05664-.50293-11.21875-100.47705-14.41895-88.94971c-.78809-4.8623,.6748-9.72754,4.01562-13.34863,3.34082-3.62012,8.07129-5.46484,12.98145-5.07666h0c6.7041,.53906,12.375,5.26562,14.1123,11.76221l28.11719,192.40137-33.53223,4.19141Z"
                fill="#3f3d58"/>
            <circle cx="531.37907" cy="83.91939" r="36.16912" fill="#ffaeb0"/>
            <path
                d="M548.58685,37.05959c7.96674,2.80007,12.52545,4.40233,16.70135,8.42524,7.05341,6.79534,8.21759,15.87636,9.29919,24.31405,.84619,6.60085,1.94031,15.13533-1.92511,24.88615-1.31915,3.32758-7.71777,18.15326-19.96539,20.02686-2.40527,.3679-8.05237,.46465-6.42468-.73705,15.06396-11.12137,21.49292-17.83537,20.96582-34.97528-.32739-10.65001-16.60095-22.14849-26.26385-24.21553-4.07056-.87063-9.48981-.07526-13.37579,3.11494-8.12134,6.66724-7.7749,34.23762-16.33853,40.00096-3.52176,2.3702-1.17508-14.31417-3.65671-11.60851-4.89639,5.33862-1.75558,13.51426-.8757,16.6278,4.17361,14.76858,12.04233,16.21409,16.11874,28.8257,4.40118,13.61598-1.138,26.4353-2.4516,29.4752-2.28485,5.28816-6.20648,14.36372-15.16577,18.71771-11.37222,5.5265-20.33612-1.67009-25.6232,4.49957-2.9556,3.44905-1.35687,7.10184-6.69492,17.82973-1.74258,3.50192-2.61386,5.25314-3.38519,5.19098-3.65018-.29391-9.56006-29.51241-.00177-56.72345,4.01144-11.41949,7.94138-22.6077,18.47552-31.35645,5.5694-4.62529,10.24045-6.28973,11.57001-11.69111,1.89938-7.71658-5.98779-11.01016-7.56885-23.18566-1.08112-8.32685-1.83966-20.23586,1.10037-27.86893,2.77618-7.20825,5.18625-13.46551,11.49454-19.13888,1.51505-1.36269,13.74548-10.62535,26.71332-10.88664,5.97021-.12011,10.35645,8.01986,17.27832,10.45261,0,0-.00012,0-.00012,0Z"
                fill="#2f2e43"/>
            <path
                d="M495.84027,96.03121c-.29666-10.71235,3.51566-26.67173,13.00839-31.64471,3.24002-1.69736,6.82138-3.64049,10.67831-6.6407,5.01532-3.90131,10.67993-7.3727,17.1084-9.00853s13.73444-1.2167,19.00427,2.12896,21.93774,16.84978,19.04681,22.09163c.49231-1.2363,.0769-3.25284,.71808-5.45003,4.97699-17.05504-6.8606-30.89539-25.13495-35.40848-3.65509-.90266-5.71942-4.3776-7.53827-5.49367-4.62915-2.84052-13.16394-6.20892-22.23871-7.16324,3.38049-7.32605-2.20813-16.06181-10.67606-18.55854s-18.46136,.52235-24.86002,6.21c-6.39865,5.68765-9.56302,13.59401-10.43442,21.37912-.43884,3.92051-.25049,8.13988,2.36957,11.27803s8.39886,4.48803,11.86737,1.84727c2.60175,15.55914-8.43445,49.44185,7.08109,54.4329,0,0,.00012,0,.00012,0Z"
                fill={color}/>
            <circle cx="550.4563" cy="235.80368" r="6.12585" fill={color}/>
            <circle cx="553.21735" cy="260.67282" r="6.12587" fill={color}/>
            <polygon
                points="268.33698 131.72719 261.8768 153.49484 302.62683 165.11047 296.57809 133.06823 268.33698 131.72719"
                fill="#ffaeb0"/>
            <path
                d="M316.36234,674.77337l.23254,18.02081c.01761,1.36432,1.13141,2.48236,2.5,2.5,11.31747,.14606,22.63498,.29211,33.95248,.43823,5.6586,.07306,11.31741,.16736,16.97623,.21912,2.18173,.01996,4.41769-.10065,6.39819-1.10718,2.11493-1.07489,3.42404-3.1601,3.72394-5.47473,.33386-2.5769-.46417-5.08209-1.50482-7.40802-.98218-2.19531-2.45959-4.61945-1.60406-7.07123,.44482-1.27478-.5065-2.73468-1.74609-3.07532-1.37744-.37848-2.62885,.46667-3.07529,1.74609-1.66431,4.76971,1.68274,8.55194,2.78732,12.93622,.31339,1.24396,.53491,2.9259-.64905,3.7774-1.40295,1.00903-3.71408,.68463-5.3287,.66382-10.65176-.13745-21.30353-.27496-31.95526-.41241-5.99161-.07733-11.98322-.15466-17.97485-.23199l2.5,2.5-.23254-18.02081c-.0415-3.21497-5.0416-3.22388-5,0h-.00003Z"
                fill="#2f2e43"/>
            <path
                d="M201.09882,641.625c-2.32265,5.54028-4.64531,11.08057-6.96796,16.62085-.38506,.91846,.23987,2.41431,1.1489,2.8233,10.47365,4.71228,20.9473,9.4245,31.42094,14.13672,5.20425,2.34149,10.38414,4.74963,15.61894,7.02191,2.47063,1.07245,5.22218,1.98761,7.88377,1.00464,2.14114-.79077,3.57483-2.49921,4.40002-4.57977,.82661-2.08411,.91811-4.367,.8644-6.58154-.04897-2.01892-.32408-4.68835,1.17255-6.29425,2.19199-2.35193-1.33601-5.89557-3.53552-3.53552-3.20885,3.44299-2.39018,7.77625-2.6432,12.05469-.08037,1.35907-.36658,3.09406-1.39861,3.93842-1.25604,1.02765-3.45699,.01654-4.81136-.58478-9.88792-4.38989-19.73337-8.87836-29.59943-13.3172l-16.84891-7.58057,1.1489,2.8233c2.32265-5.54028,4.64531-11.08057,6.96796-16.62085,.52228-1.24579-.56282-2.75018-1.74609-3.07532-1.44191-.39618-2.55104,.49554-3.0753,1.74609h0v-.00012Z"
                fill="#2f2e43"/>
            <polygon
                points="338.30112 295.74463 246.13097 304.85446 200.64568 613.70105 230.09579 618.23578 295.17838 402.07467 304.91754 641.90808 331.80319 641.90808 358.97641 348.31894 338.30112 295.74463"
                fill="#cfd1d2"/>
            <path
                d="M308.13208,126.2571s-27.54327,5.87489-47.93912-6.53997c0,0-2.60712,20.285,6.70404,29.15276s17.26553,57.19707,17.26553,57.19707l29.73361-1.77354,2.66034-62.07433s12.85825-15.51858-8.42438-15.96197c0,0-.00003-.00002-.00003-.00002Z"
                fill="#e4e5e6"/>
            <path
                d="M212.64952,351.43564c.0739,.84982,.13907,1.69604,.19595,2.53824,1.99821,29.58771,27.55162,51.96292,57.09343,49.37308,47.07812-4.1272,103.27438-10.82819,103.66046-19.3223,.66507-14.63181-27.11423-227.35588-27.11423-227.35588l-28.26834-16.85471-18.26743,16.45659s-26.28629-13.44563-39.75641-17.16507-25.27209,33.61195-25.27209,33.61195c0,0-24.93169,148.12431-22.27136,178.71809h.00002Z"
                fill={color}/>
            <g>
                <path id="uuid-a05a3423-08cc-4218-bc3c-f9bd802c62ba-300"
                      d="M402.99173,366.41696c2.9783,10.40472,10.93887,17.18704,17.77957,15.1489,6.84067-2.03812,9.97006-12.12372,6.98926-22.53174-1.13177-4.17511-3.27969-7.99677-6.26398-11.14514l-13.19434-43.91394-21.16653,7.06482,15.25449,42.54129c-.85452,4.29297-.64825,8.69437,.60153,12.83575,0,0,0,.00003,0,0v.00006Z"
                      fill="#ffaeb0"/>
                <path
                    d="M397.57031,352.2236l-.17734-.47403-35.33395-94.72595-35.6326-82.76639c-1.94769-4.52432-1.71283-9.5993,.64633-13.92458,2.35938-4.32431,6.49869-7.26482,11.35568-8.08325h0c6.63373-1.10867,13.28442,2.09567,16.55051,7.97415l74.09543,179.77412-31.50403,12.22592h-.00003Z"
                    fill={color}/>
            </g>
            <g>
                <path id="uuid-ba3ead3d-9edb-4a15-8f87-72cb079bc624-301"
                      d="M232.37363,378.40445c-.35661,10.81671-6.42728,19.33246-13.55832,19.02036-7.13103-.31207-12.6208-9.33289-12.26254-20.15338,.08168-4.32504,1.23495-8.55447,3.36334-12.33447l2.11057-45.80469,22.2495,1.70126-4.44286,44.9747c1.8736,3.95596,2.74466,8.27521,2.54033,12.59625,0,0,0,.00003,0,0l-.00002-.00003Z"
                      fill="#ffaeb0"/>
                <path
                    d="M200.64568,359.12704l28.11719-192.40137c1.7373-6.49658,7.4082-11.22314,14.1123-11.76221h0c4.91016-.38818,9.64062,1.45654,12.98145,5.07666,3.34081,3.62109,4.8037,8.48633,4.01561,13.34863l-14.41895,88.94971-11.21875,100.47705-.05664,.50293-33.53223-4.19141h.00002Z"
                    fill={color}/>
            </g>
            <g>
                <circle cx="293.64105" cy="101.76042" r="31.47656" fill="#ffb6b6"/>
                <path
                    d="M291.12894,59.44242c.9108,.53168,2.1308-.27274,2.42285-1.28613s-.04816-2.08933-.3855-3.08855l-1.69833-5.0302c-1.2045-3.56753-14.4325-.62379-17.04111-3.33914-3.93729-4.09838,1.75504-11.77693-3.87778-11.0216-7.23364,.97-14.37076,4.88694-17.73419,11.36411s-1.93149,15.51515,4.146,19.55625c-8.66177,9.92783-11.68114,20.99205-11.20438,34.1587,.47675,13.16665,14.82516,25.28427,24.18225,34.55965,2.0896-1.26669,3.98929-7.20296,2.84021-9.35946-1.14908-2.15651,.49731-4.6549-.92581-6.64128s-2.61374,1.17653-1.17499-.79857c.90787-1.24632-2.6355-4.11358-1.28601-4.85951,6.52722-3.60802,8.69815-11.74393,12.79767-17.97422,4.94479-7.51489,13.40778-12.60404,22.36386-13.44834,4.93359-.46509,10.14456,.37728,14.18845,3.24149s6.66187,7.98252,5.72412,12.84846c2.42859-2.4659,3.63748-6.07968,3.18152-9.51053s-2.5668-6.60343-5.55515-8.34941c1.81705-6.00887,.26044-12.92081-3.957-17.57068-4.21744-4.64986-21.32571-3.85791-27.48285-2.63414"
                    fill="#2f2e41"/>
                <path
                    d="M290.17276,82.41654c-8.15494,.88033-14.04306,7.94434-19.01511,14.46794-2.86575,3.76006-5.86761,7.91241-5.79565,12.6395,.07275,4.77922,3.26813,8.87844,4.79526,13.40769,2.49615,7.40319,.06335,16.21128-5.87857,21.2839,5.87131,1.11415,12.21832-3.28815,13.2319-9.17764,1.17993-6.85609-4.01733-13.47326-3.40179-20.40287,.54227-6.10506,5.35333-10.80362,9.44342-15.36836,4.09012-4.56474,7.93143-10.62203,6.04977-16.45514"
                    fill="#2f2e41"/>
            </g>
            <circle cx="302.62683" cy="160.99632" r="11.04391" fill="#e4e5e6"/>
            <g>
                <polygon
                    points="306.59085 622.06299 307.93793 651.31469 306.58887 659.40899 311.31058 668.85235 348.40952 672.89953 350.43311 655.36181 330.87186 645.24396 330.30325 628.71551 306.59085 622.06299"
                    fill="#ffaeb0"/>
                <path
                    d="M336.84146,641.90808l1.99524-44.62244-43.23032-2.99286s10.98251,80.63031,10.98251,84.00293,64.08002,3.37262,73.72256,2.02356,6.54608-10.11792,6.54608-10.11792l-39.9877-18.92865-10.02835-9.36469-.00003,.00006Z"
                    fill="#2f2e43"/>
            </g>
            <g>
                <polygon
                    points="212.24446 591.32031 204.15935 619.46472 200.29086 626.7016 201.74211 637.15942 235.59477 652.86639 243.12447 636.89862 227.82996 621.05273 237.42407 596.17444 212.24446 591.32031"
                    fill="#ffaeb0"/>
                <path
                    d="M236.39546,626.65021l6.729-39.99158-41.61366-10.38599s-6.18488,65.1272-7.26421,68.32245,59.63062,23.70258,69.19777,25.51038c9.56717,1.8078,9.43982-7.49084,9.43982-7.49084l-29.75972-25.71594-6.729-10.24841v-.00006Z"
                    fill="#2f2e43"/>
            </g>
            <path
                d="M252.64989,118.29949c-.29668-10.71235,3.51567-26.67173,13.00838-31.64471,3.24002-1.69736,6.82135-3.64049,10.67828-6.6407,5.01535-3.90131,10.6799-7.3727,17.1084-9.00853s13.73441-1.2167,19.00424,2.12896,15.5593,12.21746,12.66837,17.45932c.49231-1.2363,1.98383,4.52498,2.625,2.32779,4.97696-17.05504-2.38901-34.04089-20.66336-38.55398-3.65506-.90266-5.71942-4.3776-7.53824-5.49367-4.62915-2.84052-13.16394-6.20892-22.23868-7.16324,3.38049-7.32605-2.2081-16.06181-10.67606-18.55854s-18.46136,.52235-24.86,6.21-9.563,13.59401-10.43442,21.37912c-.43883,3.92051-.2505,8.13988,2.36958,11.27803,2.62009,3.13815,8.39886,4.48803,11.86737,1.84727,2.60175,15.55914-8.43445,49.44184,7.08109,54.4329h0l.00005-.00002Z"
                fill={color}/>
        </svg>
    );
}